<script setup lang="ts">
import router from '@/router';
import { ref, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

import { Haa, OeReportType, OeReportSubType } from '@hnw/nw-sdk/lib/index';
const { oeReport } = Haa.util().useOeReport();

const route = useRoute();
const active = ref<number>(0);
const tabList = reactive([
    { icon: require('@/assets/images/tabbar/tab_1.png'), icon_s: require('@/assets/images/tabbar/tab_1_s.png'), text: '养成必修课', path: '/main' },
    { icon: require('@/assets/images/tabbar/tab_2.png'), icon_s: require('@/assets/images/tabbar/tab_2_s.png'), text: '宠物小课堂', path: '/classroom' },
    { icon: require('@/assets/images/tabbar/tab_3.png'), icon_s: require('@/assets/images/tabbar/tab_3_s.png'), text: '宠物百科', path: '/wikipedia' },
    { icon: require('@/assets/images/tabbar/tab_4.png'), icon_s: require('@/assets/images/tabbar/tab_4_s.png'), text: '宠星座', path: '/constellation' },
])
tabList.forEach((item, index) => {
    if (item.path == route.path) active.value = index;
})
watch(
    () => route.path,
    () => {
        tabList.forEach((item, index) => {
            if (item.path == route.path) active.value = index;
        })
    }
);

const handleClick = (index: number) => {
    active.value = index;
    let tab = tabList[active.value];
    oeReport(OeReportType.Action, OeReportSubType.TabClick, tabList[active.value].text);
    router.replace({ path: tab.path });
}
</script>

<template>
    <div class="main-tab-wrap">
        <div class="main-tab-box">
            <div v-for="(item, index) in tabList" class="tab-item" @click="handleClick(index)">
                <img :src="active == index ? item.icon_s : item.icon" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.main-tab-wrap {
    .main-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0;
        img {
            width: 60px;
            height: 60px;
            margin-right: 14px;
        }
        span {
            font-size: 48px;
            color: #362C2C;
            font-weight: 600;
        }
    }
    .main-tab-box {
        display: flex;
        justify-content: space-between;
        .tab-item {
            width: 156px;
            height: 150px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
