import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home-view-wrapper" }
const _hoisted_2 = { class: "home-view-container" }
const _hoisted_3 = { class: "page-box" }

import MainTitle from '@/components/MainTitle.vue';
import MainTab from '@/components/MainTab.vue';
import WelcomeDialog from '@/components/dialog/WelcomeDialog.vue';
import IcpComp from '@/components/IcpComp.vue';

import { Haa } from '@hnw/nw-sdk/lib/index';
import { ref, onMounted } from 'vue';
import { useAdControl } from '@/hooks/useAdControl';

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

Haa.util().useLifeCycle();

const { HOME_INTER_SHOWN, HOME_BANNER_SHOW  } = useAdControl();
const showInter = ref<boolean>(false);
if (!HOME_INTER_SHOWN.value) {
    showInter.value = true;
    HOME_INTER_SHOWN.value = true;
}
onMounted(() => {
    HOME_BANNER_SHOW.value = true;
})
const handleBannerDispear = () => {
    HOME_BANNER_SHOW.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_wap_banner = _resolveComponent("wap-banner")!
  const _directive_slide = _resolveDirective("slide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "main-bg" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MainTitle),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(MainTab),
        _createVNode(_component_router_view),
        _createVNode(IcpComp),
        _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"width":"100%","height":"120px"} }, null, -1))
      ])), [
        [_directive_slide]
      ])
    ]),
    (showInter.value)
      ? (_openBlock(), _createBlock(WelcomeDialog, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_wap_banner, {
      "space-key": "Home_banner",
      onAderror: handleBannerDispear,
      onAdclose: handleBannerDispear
    })
  ]))
}
}

})