import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo.png'


const _hoisted_1 = { class: "main-title-wrap" }
const _hoisted_2 = { class: "main-title" }

import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'MainTitle',
  setup(__props) {

const appName = ref<string>(APP_NAME);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
      _createElementVNode("span", null, _toDisplayString(appName.value), 1)
    ])
  ]))
}
}

})