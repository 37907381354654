import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, nextTick, provide } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const isRouterAlive = ref<boolean>(true);

const reload = () => {
    isRouterAlive.value = false;
    nextTick(() => {
        isRouterAlive.value = true;
    })
}
provide('reload', reload);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (isRouterAlive.value)
    ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})